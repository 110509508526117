import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ico1 from "../../assets/img/i.png";
import Ico2 from "../../assets/img/info.png";
import Ico3 from "../../assets/img/warning.png";
import Ico4 from "../../assets/img/critical.png";
import Ico5 from "../../assets/img/graph1.png";
import Ico6 from "../../assets/img/graph2.png";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import ReactApexChart from "react-apexcharts";
import ActiveChart from "../../components/ActiveChart/ActiveChart";
import AgeChart from "../../components/ActiveChart/AgeChart";
import { getTableRecordWithSorting } from "../../services";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
  setExceptionAge,
} from "../../store/actions/Compensate";
import DescIcon from "../../assets/img/desc.png";
import AscIcon from "../../assets/img/asc.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import { THEME_TYPE } from "../../constants/theme";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from "../../assets/img/pslogo.png";
import SHIFT_FORM from "../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 15"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="15" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);

function ActiveManagement({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const location = useLocation();
  const { state } = location;

  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isGraphLoadingTwo, setIsGraphLoadingTwo] = useState(false);
  const [graph1Data, setGraph1Data] = useState([]);
  const [graph2Data, setGraph2Data] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [emptyDate, setEmptyDate] = useState(false);
  const [graphMaxData, setGraphMaxData] = useState("");
  const [graphMaxtwo, setGraphMaxtwo] = useState("");
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const momoDate = useSelector((state) => state.compensate.updateDate);
  const momoIndex = useSelector((state) => state.compensate.updatedIndex);
  const exceptionAge = useSelector((state) => state.compensate.exceptionAge);
  const exceptionIndex = useSelector(
    (state) => state.compensate.exceptionIndex
  );
  const [mounted, setMounted] = useState(false);

  const getTableData = async (eDate, eCost, eAge) => {
    setIsTableLoading(true);
    const parData = {
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : ""
    };
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }

    const encryptData = dataEnCrypt(parData);

    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/exceptionSummary/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data.data);

      const sum = data.data?.cost_centers.reduce(function (
        previousValue,
        currentValue
      ) {
        return {
          critical_exception_count:
            previousValue.critical_exception_count +
            currentValue.critical_exception_count,
          when_posted_days_interval_avg:
            (
              parseFloat(previousValue?.when_posted_days_interval_avg || 0) +
              parseFloat(currentValue?.when_posted_days_interval_avg || 0)
            ).toFixed(0)
        };
      });
      setTotalvalue(sum);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };
  const getHeadData = async (eDate, eCost, eAge) => {
    setIsLoading(true);
     const parData = {
       businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
     };
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }

    const encryptData = dataEnCrypt(parData);

    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/exceptionSummary/getheaderData`,
        { payloadData: encryptData }
      );
       const data = decryptedFunction(response?.data?.responseData);

      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getChartFirst = async (eDate, eCost, eAge) => {
    setIsGraphLoading(true);
    const parData = {
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };
    if (eDate) {
      parData.filter_date = eDate;
    }
    if (eCost) {
      parData.cost_center_number = eCost;
    }
    if (eAge) {
      parData.start = eAge.start;
      parData.end = eAge.end;
    }
    const encryptData = dataEnCrypt(parData);
    try {
      const url = `${API_CALL_URL_PRIVATE}/exceptionSummary/getGraphData1`;
      const requestData = {};

      const response = await axios.post(url, { payloadData: encryptData });
      const data = decryptedFunction(response?.data?.responseData);
      const g1 = data?.data?.graph?.map((item) => ({ ...item, type: "g1" }));

      setGraph1Data(g1);
      setGraphMaxData(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const getChartSecond = async (eDate, eCost, eAge) => {
    setIsGraphLoadingTwo(true);
    try {
      const url = `${API_CALL_URL_PRIVATE}/exceptionSummary/getGraphData2`;
      const parData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      if (eDate) {
        parData.filter_date = eDate;
      }
      if (eCost) {
        parData.cost_center_number = eCost;
      }
      if (eAge) {
        parData.start = eAge.start;
        parData.end = eAge.end;
      }
      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(url, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      const g2 = data.data.graph.map((item) => ({ ...item, type: "g2" }));
      setGraph2Data(g2);
      setGraphMaxtwo(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGraphLoadingTwo(false);
    }
  };

  useEffect(() => {
     if (didMountRef.current) {
       return;
     }
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    setClickCount(0);
    getMetaData();
    getTableData();
    getHeadData();
    getChartFirst();
    getChartSecond();
    getRefreshSchedule();
    didMountRef.current = true;
  }, []);

  useEffect(() => {
     if (mounted) {
       getTableData(momoDate, selectedRow, exceptionAge);
       getHeadData(momoDate, selectedRow, exceptionAge);
     } else {
       setMounted(true);
     }
  }, [momoDate]);

  useEffect(() => {
   if (mounted) {
     getTableData(momoDate, selectedRow, exceptionAge);
     getHeadData(momoDate, selectedRow, exceptionAge);
     getChartFirst(momoDate, selectedRow, exceptionAge);
   } else {
     // Component is being mounted, set mounted to true
     setMounted(true);
   }
  }, [exceptionAge]);

  const returnZero = (val) => {
    if (val) {
      const empWithCriticalException = val;
      const formattedValue = empWithCriticalException?.toFixed(1);
      return formattedValue;
    } else {
      return 0;
    }
  };

  const sortingHandle = (key, bindname) => {
    const sortdata = getTableRecordWithSorting(
      get(tableData, bindname, []),
      key,
      sortType
    );
    setSortType(sortType === "asc" ? "desc" : "asc");
    setTableData({ ...tableData, cost_centers: sortdata });
    setsortName(key);
    activityLog(SHIFT_FORM.EXCEPTION_SUMMARY_TABLE[key] ?? "", sortType);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    getChartFirst();
    getChartSecond();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setExceptionAge(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    setSelectedRow(val?._id);
    setClickCount(clickCount + 1);

    if (clickCount === 0) {
      setSelectedRow(val?._id);
      momoIndex
        ? OppositeCosHandle(val?.cost_center_number)
        : fetchSingleData(val?.cost_center_number);
    } else if (clickCount === 1) {
      if (selectedRow !== val?._id) {
        setSelectedRow(val?._id);
        momoIndex
          ? OppositeCosHandle(val?.cost_center_number)
          : fetchSingleData(val?.cost_center_number);
        setClickCount(1);
      } else {
        navigate(
          `/activedailymanagement/exceptionsummary/${val?.cost_center_slug}`,
          {
            state: {
              homeslug: state?.urlslug === "homepage" ? true : false,
            },
          }
        );
      }
    } else {
      console.log("third click");
    }
  };

  const fetchSingleData = async (e) => {
    getTableData(momoDate, e, exceptionAge);
    getHeadData(momoDate, e, exceptionAge);
    getChartFirst(momoDate, e, exceptionAge);
    getChartSecond(momoDate, e, exceptionAge);
  };

  const OppositeCosHandle = async (e) => {
    await getHeadData(momoDate, e, exceptionAge);
    // await getChartData(momoDate, e)
    await getTableData(momoDate, e, exceptionAge);
  };

  const getMetaData = async () => {
    try {
       const param = {
         application_id: 105,
       };
       const encryptData = dataEnCrypt(param);
       const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
         payloadData: encryptData,
       });
       const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

 const getRefreshSchedule = async (refreshType) => {
   try {
     const parData = {};
     if (refreshType) {
       parData.clicked = 1;
     }

     const encryptData = dataEnCrypt(parData);
     const response = await axios.post(
       `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
       { payloadData: encryptData }
     );
     const data = decryptedFunction(response?.data?.responseData);
     setScheduleData(data.data);
   } catch (error) {
     console.log(error);
   }
 };

     useEffect(() => {
       if (mounted) {
         dispatch(setUpdateDate(""));
         dispatch(setUpdateIndex(""));
         dispatch(setUpdateIndex(""));
         dispatch(setExceptionIndex(""));
         setClickCount(0);
         getMetaData();
         getTableData();
         getHeadData();
         getChartFirst();
         getChartSecond();
         getRefreshSchedule();
       } else {
         // Component is being mounted, set mounted to true
         setMounted(true);
       }
       //    getHeadData();
     }, [buisnessHeader]);

     const activityLogbutton = async () => {
       try {
         const paramData = {
           activity_id: 46,
           page_type: "Exception Summary",
           application_id: 105,
         };
         const encryptData = dataEnCrypt(paramData);
         const { data } = await axios.post(
           `${API_CALL_URL_PRIVATE}/activity-log/create`,
           { payloadData: encryptData }
         );
       } catch (err) {
         console.log(err);
       }
     };

         const activityLog = async (table_column, sortType) => {
           try {
              const paramData = {
                activity_id: 47,
                page_type: "Exceptions Summary",
                application_id: 105,
                table_name: "Critical Exceptions by Cost Center",
                table_column: table_column,
                sort_order: sortType === "asc" ? "Ascending" : "Descending",
                businessUnit: isBuisnessUnitSet,
              };

              const encryptData = dataEnCrypt(paramData);

             const { data } = await axios.post(
               `${API_CALL_URL_PRIVATE}/activity-log/create`,
               { payloadData: encryptData }
             );
           } catch (err) {
             console.log(err);
           }
         };
  

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 3 }}>
            <Link
              to={
                state?.urlslug === "homepage"
                  ? `/home`
                  : `/activedailymanagement`
              }
              className="f-14 white-text-dark-mode"
              onClick={() => {
                dispatch(setUpdateDate(""));
                dispatch(setUpdateIndex(""));
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 6 }}>
            <div className="text-center">
              <h3 className="text-center color-black m-0">
                Exceptions Summary
              </h3>
              <div className="d-flex justify-content-center align-items-baseline">
                <p
                  className="font-15 mb-0 
                  "
                >
                  {isLoading ? (
                    <HeaderLoaderText themeMode={themeMode} />
                  ) : (
                    get(headData, "exp_summary_text", "")
                  )}
                </p>

                {isLoading ? (
                  <HeaderLoaderText themeMode={themeMode} />
                ) : headData?.show_cross_button ? (
                  <button
                    type="button"
                    class="custom-close-button"
                    onClick={() => {
                      // selectedRow && momoDate ? SingleCosHandle(selectedRow) :
                      emptyActiveData();
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="pay-period color-red">
                {get(headData, "imp_notes", "")}
              </div>
            </div>
          </Col>
          <Col md={3} className="text-end mobile-center mobile-my-2">
            <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true)
                activityLogbutton();
              }}
            >
              Which Exceptions are Counted?{" "}
            </small>
          </Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>
        <Row className="gx-2">
          <Col md={12} className="mt-2">
            <div className="bg-white p-3 mobile-overflow-auto">
              <Row className="justify-content-center w-auto-col min-width-730">
                <div className="d-flex align-items-center mobile-w-auto">
                  <img src={Ico4} />
                  <div className="ms-3">
                    <div className="sp1">Critical</div>
                    <div
                      className="sp2"
                      style={{ marginTop: "12px", textAlign: "center" }}
                    >
                      {isLoading ? (
                        <LinkLoader themeMode={themeMode} />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(get(headData, "critical", 0))
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center sp2">+</div>
                <div className="d-flex align-items-center mobile-w-auto">
                  <img src={Ico3} />
                  <div className="ms-3">
                    <div className="sp1">Warning</div>
                    <div
                      className="sp2"
                      style={{ marginTop: "12px", textAlign: "center" }}
                    >
                      {isLoading ? (
                        <LinkLoader themeMode={themeMode} />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(get(headData, "warning", 0))
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center sp2">+</div>
                <div className="d-flex align-items-center mobile-w-auto">
                  <img src={Ico2} />
                  <div className="ms-3">
                    <div className="sp1">Informational</div>
                    <div
                      className="sp2"
                      style={{ marginTop: "12px", textAlign: "center" }}
                    >
                      {isLoading ? (
                        <LinkLoader themeMode={themeMode} />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(get(headData, "informational", 0))
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center sp2">=</div>
                <div className="d-flex align-items-center mobile-w-auto">
                  <div className="ms-3">
                    <div className="sp1">Total Exceptions</div>
                    <div
                      className="sp2"
                      style={{ marginTop: "12px", textAlign: "center" }}
                    >
                      {isLoading ? (
                        <LinkLoader themeMode={themeMode} />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(get(headData, "total_exception", 0))
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
          <Col md={6} className="mt-2 h-454">
            <div className="bg-white p-3 h-100">
              <div class="text-center mb-2">
                <div class="box-heading text-center">
                  Critical Exceptions by Cost Center
                </div>
              </div>
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table"
              >
                <thead>
                  <tr className="vertical-align-middle">
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle("cost_center_number", "cost_centers")
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>Cost Center</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "cost_center_number" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle(
                          "critical_exception_count",
                          "cost_centers"
                        )
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Critical
                          <br />
                          Exception Count
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "critical_exception_count" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle(
                          "when_posted_days_interval_avg",
                          "cost_centers"
                        )
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Average
                          <br />
                          Exception Age{" "}
                        </div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "when_posted_days_interval_avg" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="crossi">
                  {!isTableLoading ? (
                    tableData?.cost_centers?.length > 0 ? (
                      get(tableData, "cost_centers", []).map((val, ind) => {
                        return (
                          <tr
                            key={ind}
                            style={{ cursor: "pointer" }}
                            className={
                              selectedRow === val?._id
                                ? "selected-active-row"
                                : ""
                            }
                            onClick={() => handleDoubleExceptional(val)}
                          >
                            <td>
                              <div
                              // to={`/activedailymanagement/exceptionsummary/${val.cost_center_slug}`}
                              >
                                {val.cost_center_number} -{" "}
                                {val.cost_center_name}
                              </div>
                            </td>
                            <td className="text-center">
                              <div
                              // onClick={() => handleDoubleExceptional(val)}
                              // to={`/activedailymanagement/exceptionsummary/${val.cost_center_slug}`}
                              >
                                {" "}
                                {val.critical_exception_count}
                              </div>
                            </td>
                            <td className="text-center">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                // onClick={() => handleDoubleExceptional(val)}
                                // to={`/activedailymanagement/exceptionsummary/${val.cost_center_slug}`}
                              >
                                {val?.when_posted_days_interval_avg?.toFixed(0)}
                                {/* {returnZero(val.when_posted_days_interval_avg)} */}
                                {/* <span className="custom-pos">
                              {(selectedRow === val?._id) ? (
                                <button
                                  type="button"
                                  class="custom-close-button"
                                  style={{"background":"transparent"}}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    emptyActiveData();
                                    setSelectedRow(null);
                                  }}
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              ) : ( ""
                              )}
                           </span> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
                <tbody className="ftr">
                  {isTableLoading ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : tableData?.cost_centers?.length > 0 && !isEmpty(totalvalue) ? (
                    <tr className="bt">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {/* {get(totalvalue, "critical_exception_count", 0)} */}
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            // minimumFractionDigits: 1,
                            // maximumFractionDigits: 1,
                          }).format(
                            get(totalvalue, "critical_exception_count", 0)
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(
                            tableData?.cost_centers?.filter(
                              (center) =>
                                center.when_posted_days_interval_avg !== 0
                            )?.length
                              ? get(
                                  totalvalue,
                                  "when_posted_days_interval_avg",
                                  0
                                ) /
                                  tableData?.cost_centers?.filter(
                                    (center) =>
                                      center.when_posted_days_interval_avg !== 0
                                  )?.length
                              : 0
                          )}
                          {/* {(
                          get(totalvalue, "emp_with_critical_exception", 0) /
                          get(tableData, "cost_centers", 0)?.length
                        )?.toFixed(1)} */}
                        </strong>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="border-bottom-5 h-half">
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <ActiveChart
                  data={graph1Data}
                  maxData={graphMaxData}
                  chartColor="#D7263D"
                  chartName="Critical Exceptions by Day"
                  indexNum={momoIndex}
                  tooltip="Critical Exceptions"
                />
              )}
            </div>
            <div className="border-top-5 h-half">
              {isGraphLoadingTwo ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <AgeChart
                  data={graph2Data}
                  maxData={graphMaxtwo}
                  // chartColor="#3c9df9"
                  chartName="Exception Aging in Days"
                  indexNum={exceptionIndex}
                  tooltip="Exception Aging in Days"
                />
              )}
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              {/* <span>{headData?.current_file_msg}</span> */}
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
              {/* <span className="col-6"><span className=" text-start  p-2 bg-white d-block">{headData?.upcomming_file_msg}</span></span> */}
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default ActiveManagement;
