import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/o.png";
import Ico2 from "../../assets/img/t.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";

import Icoa from "../../assets/img/1.png";
import Icob from "../../assets/img/2.png";
import Icoc from "../../assets/img/3.png";
import Icod from "../../assets/img/4.png";

import MissedShift from "../../assets/img/missed_shift.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from "../../assets/img/pslogo.png";
import ContentLoader from "react-content-loader";
import MmbIcon from "../../assets/img/mmb-mini.png";
import ScheduleIcon from "../../assets/img/schedule-mini.png";
import OvertimeIcon from "../../assets/img/overtime-mini.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import { decryptedFunction, dataEnCrypt } from "../../utils/utils";

const BoxLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="195" />
  </ContentLoader>
);

function Applications(props) {
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [dashboardData, setDashboardData] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [themeMode, setTheme] = useContext(ThemeContext);

  const boxdata = props.data;
  // const isLoader = props.isLoader;
  const { issearch } = props;
  const [metaData, setMetaData] = useState([]);
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [isLoader, setIsLoader] = useState(false);

  const getDashboardData = async () => {
    setIsLoader(true);
    try {
      const paramData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/home`, {
        payloadData: encryptData,
      });
      const data = await decryptedFunction(response?.data?.responseData);
      setDashboardData(data.data);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(true);
      console.log(error);
    }
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 100,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getMetaData();
  }, []);

  useEffect(() => {
    document.title = metaData?.meta_title || "PERFECTSHIFT";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  useEffect(() => {
    document.title = metaData?.meta_title || "PERFECTSHIFT";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);



    useEffect(() => {
      if (mounted) {
       getDashboardData();
      } else {
        setMounted(true);
      }
    }, [buisnessHeader]);

  return (
    <PageLayout>
      <div className="row gx-2 mt-2">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <h2
              className="fw-normal color-black"
              style={{ textTransform: "capitalize" }}
            >
              Hello {get(userDatas, "employee_name", "")}
            </h2>
          </div>
          {isLoader ? (
            <BoxLoader themeMode={themeMode} />
          ) : (
            <div className="row home-widget gx-2 gy-2">
              {dashboardData?.map((svc) => (
                <div className="col-md-12">
                  <div className="widget-tabs ">
                    <Link className="boxlink" to={`/${svc?.slug}`}>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={get(svc, "logo")}
                          style={{ height: "38px", width: "38px" }}
                          alt=""
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 className="m-0">{svc?.title}</h3>
                      </div>
                      {svc?.slug === "realtime" && (
                        <div>
                          <div class="row header-bar gx-3 align-items-center primary-font 11 tttttttttttttt">
                            <div class="col col-66">
                              <div class="position-relative bg-white h-100 box-h ">
                                <div class="d-flex align-items-center">
                                  <div class="i d-flex align-items-center py-2">
                                    <img
                                      src={Iconone}
                                      alt=""
                                      class="img-fluid"
                                    />{" "}
                                    &nbsp;
                                    <span class=" big-num  f-35 lh-1 color-green">
                                      {svc?.header?.availble_beds}
                                    </span>
                                  </div>
                                  <div class="ps-2">
                                    <div class="pe-0 primary-font">
                                      <span class="f-25 color-black  color-green">
                                        Available Beds
                                      </span>
                                      &nbsp;{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col col-66">
                              <div class="position-relative bg-white h-100 box-h ">
                                <div class="d-flex align-items-center">
                                  <div class="i d-flex align-items-center py-2">
                                    <img
                                      src={Iconthree}
                                      alt=""
                                      class="img-fluid"
                                    />{" "}
                                    &nbsp;
                                    <span class=" big-num  f-35 lh-1 color-orange">
                                      {svc?.header?.total_variance?.toFixed(1)}
                                    </span>
                                  </div>
                                  <div class="ps-2">
                                    <div class="pe-0 primary-font">
                                      <span class="f-25 color-black  color-orange">
                                        Hours Variance
                                      </span>
                                      &nbsp;{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div class="col col-66">
                              <div class="position-relative bg-white h-100 box-h ">
                                <div class="d-flex align-items-center">
                                  <div class="i d-flex align-items-center py-2">
                                    <img
                                      src={Iconfour}
                                      alt=""
                                      class="img-fluid"
                                    />{" "}
                                    &nbsp;
                                    <span class=" big-num  f-35 lh-1 o-color">
                                      {svc?.header?.total_hpous?.toFixed(3)}
                                    </span>
                                  </div>
                                  <div class="ps-2">
                                    <div class="pe-0 primary-font">
                                      <span class="f-25 color-black color-purple">
                                        HPUOS
                                      </span>
                                      &nbsp;{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col col-66">
                              <div class="position-relative bg-white h-100 box-h ">
                                <div class="d-flex align-items-center">
                                  <div class="i d-flex align-items-center py-2">
                                    <img
                                      src={Icontwo}
                                      alt=""
                                      class="img-fluid"
                                    />{" "}
                                    &nbsp;
                                    <span class=" big-num  f-35 lh-1 blue-color">
                                      {svc?.header?.total_compliance}
                                    </span>
                                  </div>
                                  <div class="ps-2">
                                    <div class="pe-0 primary-font">
                                      <span class="f-25 color-black blue-color">
                                        Compliance
                                      </span>
                                      &nbsp;{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div class="col col-66">
                              <div class="position-relative bg-white h-100 box-h ">
                                <div class="d-flex align-items-center">
                                  <div class="i d-flex align-items-center py-2">
                                    <img
                                      src={MissedShift}
                                      alt=""
                                      class="img-fluid"
                                    />{" "}
                                    &nbsp;
                                    <span class=" big-num  f-35 lh-1 o-color">
                                      {svc?.header?.missed_shift}
                                    </span>
                                  </div>
                                  <div class="ps-2">
                                    <div class="pe-0 primary-font">
                                      <span class="f-25 color-black blue-color">
                                        Missed Entries
                                      </span>
                                      &nbsp;{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {svc?.slug === "activedailymanagement" && (
                        <div className="">
                          <div class="p-2">
                            <div class="gx-3  w-auto-col row">
                              <div class="col col-66">
                                <Link
                                  to={`/activedailymanagement/exceptions-summary`}
                                  state={{
                                    urlslug: "homepage",
                                  }}
                                >
                                  <div class="d-flex align-items-center position-relative bg-white h-100 box-h ">
                                    <img src={Icoa} />
                                    <span
                                      class="f-35 o-color me-2 ms-2 "
                                      style={{
                                        marginLeft: 0 + "px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }).format(
                                        svc?.header?.critical_exceptions || 0
                                      )}
                                    </span>
                                    <div class="ms-2">
                                      <div class="f-25">
                                        {/* {process.env.React_App_ENVIRONMENT !==
                                        "production" ? (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Critical Exceptions
                                          </span>
                                        ) : (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Critical Exceptions
                                            <br /> Exceeding Approved Age
                                          </span>
                                        )} */}
                                        <span class="f-25 color-black  color-orange primary-font">
                                          Critical Exceptions
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              {svc?.header?.unscheduled_hours_display ? (
                                <div class="col col-66">
                                  <Link
                                    to={`/activedailymanagement/schedule-summary`}
                                    state={{
                                      urlslug: "homepage",
                                    }}
                                  >
                                    <div class="d-flex align-items-center position-relative bg-white h-100 box-h ">
                                      <img src={ScheduleIcon} />
                                      <span
                                        class="f-35 o-color me-2 ms-2 "
                                        style={{
                                          marginLeft: 0 + "px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }).format(
                                          svc?.header
                                            ?.unscheduled_worked_hours || 0
                                        )}
                                      </span>
                                      <div class="ms-2">
                                        <div class="f-25">
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Unscheduled Hours Worked
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                              <div class="col col-66">
                                <Link
                                  to={`/activedailymanagement/missedmealbreak-summary`}
                                  state={{
                                    urlslug: "homepage",
                                  }}
                                >
                                  <div class="d-flex align-items-center position-relative bg-white h-100 box-h ">
                                    <img src={MmbIcon} />
                                    <span
                                      class="f-35 o-color me-2 ms-2 "
                                      style={{
                                        marginLeft: 0 + "px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }).format(svc?.header?.mmb_hours || 0)}
                                    </span>
                                    <div class="ms-2">
                                      <div class="f-25">
                                        {/* {process.env.React_App_ENVIRONMENT !==
                                        "production" ? (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Missed Meal(s) and Break(s)
                                          </span>
                                        ) : (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Missed Meals/Breaks
                                          </span>
                                        )} */}
                                        <span class="f-25 color-black  color-orange primary-font">
                                          Missed Meal(s) and Break(s)
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                              <div class="col col-66">
                                <Link
                                  to={`/activedailymanagement/overtime-summary`}
                                  state={{
                                    urlslug: "homepage",
                                  }}
                                >
                                  <div class="d-flex align-items-center position-relative bg-white h-100 box-h ">
                                    <img src={OvertimeIcon} />
                                    {/* {process.env.React_App_ENVIRONMENT !==
                                    "production" ? (
                                      <span
                                        class="f-35 o-color me-2 ms-2 "
                                        style={{
                                          marginLeft: 0 + "px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }).format(
                                          svc?.header?.overtime_hours || 0
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        class="f-35 o-color me-2 ms-2 "
                                        style={{
                                          marginLeft: 0 + "px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }).format(
                                          svc?.header
                                            ?.overtime_incedental_hours || 0
                                        )}
                                      </span>
                                    )} */}
                                    <span
                                      class="f-35 o-color me-2 ms-2 "
                                      style={{
                                        marginLeft: 0 + "px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }).format(
                                        svc?.header?.overtime_hours || 0
                                      )}
                                    </span>

                                    <div class="ms-2">
                                      <div class="f-25">
                                        {/* {process.env.React_App_ENVIRONMENT !==
                                        "production" ? (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Overtime Hours
                                          </span>
                                        ) : (
                                          <span class="f-25 color-black  color-orange primary-font">
                                            Employees With
                                            <br />
                                            Incidental Overtime
                                          </span>
                                        )} */}
                                        <span class="f-25 color-black  color-orange primary-font">
                                          Overtime Hours
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                </div>
              ))}
              {/* <div className="col-md-6">
              <div className="widget-tabs bg-white">
              <Link className="boxlink" to={`/activedailymanagement`}> 
                <div className="mb-4">
                  <img src={Diconethree} alt="" width={35}/>
                </div>
                <h3>Active Daily Management</h3>
                <p className="greyText">Lorem ipsum is a placeholder text commonly</p>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="widget-tabs bg-white">
                <div className="mb-4">
                  <img src={Ico3} alt="" />
                </div>
                <h3>Customizable Widget 3</h3>
                <p className="greyText">Lorem ipsum is a placeholder text commonly</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="widget-tabs bg-white">
                <div className="mb-4">
                  <img src={Ico4} alt="" />
                </div>
                <h3>Customizable Widget 4</h3>
                <p className="greyText">Lorem ipsum is a placeholder text commonly</p>
              </div>
            </div> */}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default Applications;
