
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";
import { Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
// import MultiStepForm from './MultiStepForm';
import Accordion from 'react-bootstrap/Accordion';

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="w-950"
    >
      <Modal.Header closeButton className="custom-font-size text-center border-0 p-0">

      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15">

        <Row>
          <Col md={12}>
            <Table striped hover className='d-padding-0'>
              <tr>
                <td><div><strong>Cost Center </strong></div>  <div>7th Avenue Surgery</div></td>
                <td className='left-right-border'><div><strong>Name</strong></div>  <div>7th Avenue Surgery</div></td>
                <td><div><strong>Pay Period End Date </strong></div>  <div>1/15/2022</div></td>
              </tr>
            </Table>
            {/* <Row>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Cost Center :</strong>  7th Avenue Surgery</p></Col>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Name:</strong>  7th Avenue Surgery</p></Col>
      <Col md={4}><p className=" fw-normal color-black f-18  mb-0"><strong>Pay Period End Date :</strong>  1/15/2022</p></Col>
      </Row> */}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div class="overlfow-auto">
              <table class="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table min-w-1100  table-width table">
                <thead>
                  <tr class="vertical-align-middle custom-pad bg-dark-custom">
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center"></div></th>
                    <th class=" cursor-pointer"><div class="d-flex align-items-center">UOS</div></th>
                    <th class=" cursor-pointer"><div class="d-flex align-items-center">WHPOUS</div></th>
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center">Worked<br/>FTEs</div></th>
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center">Agency<br/>FTEs</div></th>
                    <th class=" cursor-pointer"><div class="d-flex align-items-center">EDU<br/>FTEs</div></th>
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center">Orient<br/>FTEs</div></th>
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center">Paid<br/>FTEs</div></th>
                    <th class=" cursor-pointer"><div class="d-flex  align-items-center">Variance</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><div className='f-16'> <strong>Current</strong></div></td>
                    <td>65</td>
                    <td><div><span className='text-danger'>22.543!</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td><div><span className='text-dangert'>22.543!</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td>3.0</td>
                    <td>0.2</td>
                    <td>0.0</td>
                    <td><div><span className='text-danger'>19.5</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td>$18.1</td>
                  </tr>
                  <tr>
                    <td><div className='f-16'> <strong>Rolling-4</strong></div></td>
                    <td>65</td>
                    <td><div><span className='text-danger'>22.543!</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td><div><span className='text-dangert'>22.543!</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td>3.0</td>
                    <td>0.2</td>
                    <td>0.0</td>
                    <td><div><span className='text-danger'>19.5</span><br /><small>Goal:19.100(-18.03%)</small></div></td>
                    <td>$18.1</td>
                  </tr>
                </tbody></table></div>
          </Col>
        </Row>
        <Row>


          <Col md={12} className='mt-3'>


            <h4 className='justify-content-center f-24-custom mb-2'> <span className='bg-dark-custom'>Answer the following based on the most recent rolling - 4 pay period</span></h4>


            <div className='scroll-area mt-4 pb'>
              {/* <MultiStepForm /> */}
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Q1. What obstacles are preventing you from meeting your productive FTE Budget?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={2} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Q2. If you subtract your known variables causing your variance (orientation, PSA hours, for example) what is your new variance?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Q3. Which 1-2 obstacles do you plan to address now?                  </Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Q4. How do you plan to address it/them? (Please be specific)</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Q5. What do you expect? By taking above action(s), how will it impact your productive FTE? (it is recommended that you work with your financial analyst for this section)
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Q6. When can we see what you have learned?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Q7. What obstacles are preventing you from meeting your productive FTE Budget?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Q8. What obstacles are preventing you from meeting your productive FTE Budget?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Q9. What obstacles are preventing you from meeting your productive FTE Budget?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>Q10. What obstacles are preventing you from meeting your productive FTE Budget?</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} className='w-100 text-start h200' />
                      </Form.Group>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className='text-center'><button className='green-btn'>Submit</button></div>
          </Col>


        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal

