import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { createMemoryHistory } from "history";
import { store } from "./store";
import { Provider } from "react-redux";
import { SocketContext, socket } from "./socket";
// import { msalConfig } from "./pages/auth/login/authConfig";
import { msalConfig as defaultMsalConfig } from "./pages/auth/login/authConfig";

const urlParams = new URLSearchParams(window.location.search);
const tenantId = urlParams.get("tenantId") || defaultMsalConfig.auth.tenantId;
const clientId = urlParams.get("clientId") || defaultMsalConfig.auth.clientId;

// Update msalConfig dynamically
const msalConfig = {
  ...defaultMsalConfig,
  auth: {
    ...defaultMsalConfig.auth,
    tenantId,
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
  },
};

console.log("msalConfig", msalConfig);


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createMemoryHistory();
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <SocketContext.Provider value={socket}>
        <Provider store={store}>
          <BrowserRouter location={history.location} navigator={history}>
            <App />
          </BrowserRouter>
        </Provider>
      </SocketContext.Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
